import React, {Component} from 'react';
import { Redirect } from 'react-router-dom';
import {Modal, ModalBody, ModalHeader} from 'reactstrap';
import { unmountComponentAtNode } from "react-dom";
import {base, appDatabasePrimaryFunctions} from '../../base';
import { confirmAlert } from '../utils/react-confirm-alert';
import Confetti from 'react-confetti';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import { getDistance } from 'geolib';
import Loading from '../utils/Loading';
import ReactPlayer from 'react-player';
import '../../styles/css/Home.css';
import '../../styles/css/ConfirmAlertCustom.css';
import '../../styles/css/main.css';
import '../../styles/css/CreatorAwards.css';
import { validateEmail, isObjectEmpty, getRandomInt, changeMilesToMeters } from '../utils/HelpfulFunction';
import { Textfit } from 'react-textfit';

class Main_App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userEmail: props.appStorage.getItem('userEmail') || props.appStorage.getItem('tempToken') || false,
            gameActive: false,
            seenVideo: false,
            tenantRules: {},
            currentPick: {},
            winner: "",
            racers: [],
            selectedItem: 0,
            videoState: 0,
            status: 0,
            timeToSendEmails: null,
            videoDuration: "0:00",
            width: 0,
            height: 0,
            highlightedPick: false,
            questionAnswered: false,
            formFilledOut: false,
            userAnswers: [],
            alreadyUpdate: "",
            currentGameId: null,
            locationChecking: true,
            loading: true,
            startConfetti: false,
            videoURL: "",
            imageLoading: true,
            firstLoad: true,
            modal: false
        };
        this.logout = this.logout.bind(this);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
        this.winnerToggle = this.winnerToggle.bind(this);
        this.toggleRules = this.toggleRules.bind(this);
    }

    handleGameMethods(){
        const userEmail = this.state.userEmail;
        const isMlbApp =  process.env.REACT_APP_IS_MLB_TEAM === "true";
        let base64EncodedEmail = btoa(userEmail);
        if(!validateEmail(userEmail)){
            base64EncodedEmail = userEmail;
        }
        this.racersRef = base.bindToState('currentGame/racers', {
            context: this,
            state: 'racers',
            asArray: true
        })
        this.currentGameKeyRef = base.listenTo(`currentGame/id`, {
            context: this,
            then(key){
                this.logUserActivity(key)
                this.removeReactAlert();
                this.setState({
                    currentGameId:key,
                    highlightedPick: false
                })
            }
        });
        this.currentPickRef = base.listenTo('userPicks/'+base64EncodedEmail, {
            context: this,
            then(currentPick) {
                if (this.props.variables && this.props.variables.collectDistance && !currentPick.pickid && this.props.variables.latitude && this.props.variables.acceptableDistance) {
                    this.checkUsersLocation();
                } else {
                    this.setState({
                        locationChecking: false,
                        loading: false,
                        currentPick: currentPick
                    })
                }
                if (currentPick && currentPick.winner) {
                    this.setState({
                        winner: currentPick.winner
                    })
                }
                if(this.state.firstLoad && currentPick && currentPick.pickid){
                    confirmAlert({
                        title: this.props.variables.alreadyPlayedHeader || "Nice!",
                        variables: this.props.variables,
                        message: this.props.variables.alreadyPlayedMessage || "You already have a selection registered",
                        buttons: [
                            {
                                label: 'OK',
                            }
                        ]
                    })
                }
                this.setState({firstLoad:false});
            }

        })
        this.gameActiveRef = base.bindToState('currentGame/active', {
            context: this,
            state: 'gameActive'
        })
        this.winnerRef = base.listenTo('currentGame/winner', {
            context: this,
            state: 'winner',
            async then(winner) {
                let racers = this.state.racers || [];
                let emptyWinner = false;
                if (isObjectEmpty(winner)) {
                    if (racers.length === 0) {
                        const racersRef = await appDatabasePrimaryFunctions.ref('currentGame/racers').once('value');
                        racersRef.forEach(function (racerRef) {
                            racers.push(racerRef.val())
                        })
                    }
                    if(racers.length > 0){
                        //Select random winner
                        const randomWinnerPicked = getRandomInt(this.state.racers.length);
                        const winnerRacer = racers[randomWinnerPicked];
                        winner = winnerRacer.id;
                        emptyWinner = true;
                    }
                }
                //important to leave && winner for other types of games
                if ((isObjectEmpty(this.state.winner) || !this.state.winner) && winner) {
                    this.setState({
                        winner: winner,
                        emptyWinner: emptyWinner
                    }, () => {
                        if (isObjectEmpty(this.state.currentPick)) {
                            this.startVideo('shuffle');
                        }
                    });
                }
            }
        })
        this.currentGameTimingRef = base.listenTo('currentGame/gameTiming', {
            context: this,
            state: 'gameType',
            then(gameType){
                this.setState({
                    gameType: gameType
                });
            }
        })
        this.prizesWonRef = base.listenTo('prizeWon/'+base64EncodedEmail, {
            context: this,
            then(winnerPrize){
                if(winnerPrize && !isMlbApp){
                    this.setState({
                        rewardName: winnerPrize.rewardName,
                        winningPrizeImage: winnerPrize.rewardImage,
                        winningCode: winnerPrize.code,
                        isWinningPrize: winnerPrize.isWinning
                    })
                } else {
                    this.setState({
                        rewardName: null,
                        winningPrizeImage: null,
                        winningCode: null
                    })
                }
                if(this.state.gameType === "immediate"){
                    return;
                }
                let winners = [];
                const racers = this.state.racers || [];
                for(const i in racers){
                    const racer = racers[i];
                    if(racer.winner){
                        winners.push(racer.id);
                    }
                }
                let prizeResult = "noResult";
                if(winnerPrize && winnerPrize.isWinning){
                    prizeResult = "winnerPrize"
                } else if(winnerPrize && winnerPrize.isWinning === false) {
                    prizeResult = "loserPrize"
                } else if(winners.indexOf(this.state.currentPick.pickid) !== -1) {
                    prizeResult = "winnerNoPrize"
                } else if(this.state.currentPick.pickid) {
                    prizeResult = "loserNoPrize"
                }
                this.setState({
                    prizeResult: prizeResult
                })
            }
        });
        this.currentGameSentEmail = base.listenTo('currentGame/sentEmail', {
            context: this,
            then(sentEmailBoolean){
                if(!this.state.sentEmail && sentEmailBoolean){
                    this.startVideo()
                }
                this.setState({
                    sentEmail: sentEmailBoolean
                })
            }
        })

        this.statusRef = base.listenTo('currentGame/status', {
            context: this,
            state: 'status',
            then(status){
                this.setState({
                    status: status
                });
            }
        })
        this.currentVideoStateRef = base.listenTo('currentGame/videoState', {
            context: this,
            state: 'videoState',
            then(videoState){
                this.setState({
                    videoState: videoState
                }, () => {
                    let racerWinnerVideo;
                    for(const racerIndex in this.state.racers){
                        if(this.state.racers[racerIndex].id === this.state.winner){
                            racerWinnerVideo = this.state.racers[racerIndex].racerWinningVideoLink;
                        }
                    }
                })
            }
        })
    }

    componentDidMount() {
        this.handleGameMethods();

        this.tenantRulesRef = base.bindToState(`tenantRules`, {
            context: this,
            state: 'tenantRules',
        });
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
        this.props.loadPage();
    }

    setVideoOverOrToggled(){
        this.setState({
            videoURL: "",
            modal: !this.state.modal
        }, ()=>{
            if(this.state.modal === false && this.state.currentPick.pickid){
                if(this.state.prizeResult){
                    this.alertIfWonOrLost(this.state.prizeResult);
                } else if(this.state.gameType !== "immediate"){
                    this.alertIfWonOrLost();
                } else {
                    this.checkIfGetsPrize(true);
                }
            }
        })
    }

    removeReactAlert(){
      document.body.classList.remove('react-confirm-alert-body-element')
      const target = document.getElementById('react-confirm-alert')
      if(target){
        unmountComponentAtNode(target)
        target.parentNode.removeChild(target)
      }
      const svg = document.getElementById('react-confirm-alert-firm-svg')
      if(svg){
        svg.parentNode.removeChild(svg)
        document.body.children[0].classList.remove('react-confirm-alert-blur')
      }
    }

    componentWillUnmount() {
        base.removeBinding(this.currentPickRef);
        base.removeBinding(this.racersRef);
        base.removeBinding(this.gameActiveRef);
        base.removeBinding(this.currentGameKeyRef);
        base.removeBinding(this.winnerRef);
        base.removeBinding(this.statusRef);
        base.removeBinding(this.currentVideoStateRef);
        base.removeBinding(this.prizesWonRef);
        base.removeBinding(this.currentGameTimingRef);
        base.removeBinding(this.tenantRulesRef);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevState.currentPick.pickid && !this.state.currentPick.pickid){
            this.removeReactAlert();
            this.setState({
                startConfetti:false,
                winnerModal: false,
                prizeResult: null
            })
        }
        if(!this.state.loading && prevState.gameActive === false && this.state.gameActive === true && !this.state.currentPick.pickid && (typeof this.state.winner === "string")){
            this.startVideo("shuffle")
        }
    }

    updateWindowDimensions() {
      this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    winnerToggle() {
        this.setState({
            winnerModal: !this.state.winnerModal,
            startConfetti: false
        });
    }

    getLocation(){
        return new Promise((resolve, reject) => {
            if (!navigator.geolocation) {
                reject("Geolocation is not supported by your browser. Please change browsers to play!");
            } else {
                const toCheckLatitude = this.props.variables.latitude || 51.525;
                const toCheckLongitude = this.props.variables.longitude || 7.4575;
                navigator.geolocation.getCurrentPosition(
                    function(position) {
                        resolve(getDistance({latitude: position.coords.latitude, longitude: position.coords.longitude}, {
                            latitude: toCheckLatitude,
                            longitude: toCheckLongitude,
                        }))
                    },
                    (err) => {
                        if(err.message === "User denied Geolocation"){
                            reject("Position could not be determined because the browser does not have permission.  Please go to your browsers settings to allow it access to your location");
                        } else {
                            console.log(err.message);
                            reject("An unknown error occurred, check your internet connection and try again");
                        }
                    }
                );
            }
        })
    }

    toggleRules() {
        this.setState({
            modalRules: !this.state.modalRules
        });
    }

    toggleSupport(){
        this.setState({
            modalSupport: !this.state.modalSupport
        });
    }

    getLocationPermission(){
        const locationErrorTitle = "Location Error";
        this.getLocation().then(distance_meters => {
            this.props.appStorage.setItem('locationPermissions', "true");
            const allowed_distance = changeMilesToMeters(this.props.variables.acceptableDistance || 100); //In miles
            if(distance_meters <= allowed_distance){
                this.setState({
                    modal:false,
                    loading:false,
                    locationChecking: false
                });
            } else {
                this.setState({
                    modal:false,
                    loading:false
                });
                confirmAlert({
                    title: locationErrorTitle,
                    variables: this.props.variables,
                    message: "Too far from game area to participate!",
                    buttons: [
                        {
                            label: 'Retry',
                            onClick: () => {
                                this.checkUsersLocation()
                            }
                        }
                    ]
                });
            }
        }, error => {
            this.setState({
                modal:false,
                loading:false
            });
            this.props.appStorage.setItem('locationPermissions', "false");
            if(typeof error != "string"){
                error = error.message
            }
            confirmAlert({
                title: locationErrorTitle,
                variables: this.props.variables,
                message: error,
                buttons: [
                    {
                        label: 'Retry',
                        onClick: () => {
                            this.checkUsersLocation()
                        }
                    }
                ]
            });
        })
    }

    checkUsersLocation(){
        const variables = this.props.variables;
        const locationPermissions = this.props.appStorage.getItem('locationPermissions');
        if(!this.props.variables.collectDistance) {
            this.setState({locationChecking: false})
        } else if(locationPermissions === "false" || !locationPermissions){
            const locationPermissionsHeader = variables.locationPermissionsHeader || "Location Permissions Required";
            const locationPermissionsBody = variables.locationPermissionsBody || "We need your location in order to play! We use this information to make your experience better";
            confirmAlert({
                title: locationPermissionsHeader,
                variables: variables,
                message: locationPermissionsBody,
                buttons: [
                    {
                        label: 'Yes',
                        onClick: () => {
                            this.setState({
                                loading: true,
                            });
                            this.getLocationPermission()
                        }
                    }
                ],
            })
        } else {
            this.setState({
                loading: true,
            });
            this.getLocationPermission()
        }
    }

    logout(){
        this.props.appStorage.removeItem('userEmail');
        this.props.appStorage.removeItem('verifiedAge');
        this.props.appStorage.removeItem('birthday');
        this.props.appStorage.removeItem('locationPermissions');
        this.props.appStorage.removeItem('id_token');
        this.setState({
            userEmail:false
        })
    }

    logUserActivity(gameId){
        const vm = this;
        if(gameId){
            let base64EncodedEmail = btoa(this.state.userEmail);
            if(!validateEmail(this.state.userEmail)){
                base64EncodedEmail = this.state.userEmail;
            }
            base.post('userGameHistory/'+base64EncodedEmail+`/${gameId}` , {
                data:gameId,
                then(err){
                    if(!err){
                        vm.setState({
                            alreadyUpdate: gameId,
                        })
                        console.log("user game logged!")
                    }
                }
            })
        }
    }

    onClickPick(pickClicked, position) {
        const tenantVariables = this.props.variables || {};
        const frozenHeader = tenantVariables.frozenHeader || "Picks Frozen!"
        const frozenMessage = tenantVariables.frozenMessage || "No more picks are allowed!"
        const alreadyHappenedHeader = tenantVariables.alreadyHappenedHeader || "Oh no!"
        const alreadyHappenedMessage = tenantVariables.alreadyHappenedMessage || "The contest already happened but come back next time to try again!"
        if (this.state.currentPick.pickid) {
            confirmAlert({
                title: 'Sorry!',
                variables: tenantVariables,
                message: "Already played",
                buttons: [
                  {
                    label: 'OK',
                  }
                ]
              })
            return
        }
        if (this.state.status === 2) {
            confirmAlert({
                title: alreadyHappenedHeader,
                variables: tenantVariables,
                message: alreadyHappenedMessage,
                buttons: [
                    {
                        label: 'OK',
                    }
                ]
            });
            return;
        } else if (this.state.status === 1) {
            confirmAlert({
                title: frozenHeader,
                variables: tenantVariables,
                message: frozenMessage,
                buttons: [
                    {
                        label: 'OK',
                    }
                ]
            });
            return;
        }
        this.setState({
            highlightedPick: pickClicked.id
        })
    }

    onSubmitAnswer(){
        const tenantVariables = this.props.variables || {};
        const highlightedPick = this.state.highlightedPick;
        const frozenHeader = tenantVariables.frozenHeader || "Picks Frozen!"
        const frozenMessage = tenantVariables.frozenMessage || "No more picks are allowed!"
        const alreadyHappenedHeader = tenantVariables.alreadyHappenedHeader || "Oh no!"
        const alreadyHappenedMessage = tenantVariables.alreadyHappenedMessage || "The contest already happened but come back next time to try again!"
        if(!highlightedPick){
            return;
        }
        if(this.state.videoState === 2){
            confirmAlert({
                title: alreadyHappenedHeader,
                variables: tenantVariables,
                message: alreadyHappenedMessage,
                buttons: [
                    {
                        label: 'OK',
                    }
                ]
            });
            return;
        } else if(this.state.videoState === 1){
            confirmAlert({
                title: frozenHeader,
                variables: tenantVariables,
                message: frozenMessage,
                buttons: [
                    {
                        label: 'OK',
                    }
                ]
            });
            return;
        }
        const userEmail = this.state.userEmail;
        let base64EncodedEmail = btoa(userEmail);
        if(!validateEmail(userEmail)){
            base64EncodedEmail = userEmail;
        }
      var postToUserPicks = {};
      var userSelectionObject = {};
      const vm = this;
      userSelectionObject.pickid = highlightedPick;
      userSelectionObject.uid = base64EncodedEmail;
      userSelectionObject.timeStamp = Date.now();
      if(this.state.emptyWinner){
          userSelectionObject.winner = this.state.winner;
      }
      postToUserPicks["/userPicks/" + base64EncodedEmail] = userSelectionObject;
      const gameHeaderPick = tenantVariables.selectCharacterGameHeader || "Select This Character?";
      const gameBodyPick = tenantVariables.selectCharacterGameBody || "Once you choose you're locked in & can't switch.";
      confirmAlert({
          title: gameHeaderPick,
          variables: vm.props.variables,
          message: gameBodyPick,
          buttons: [
            {
              label: 'Yes',
              onClick: () => {
                appDatabasePrimaryFunctions.ref().update(postToUserPicks, function(error){
                  if(!error){
                    vm.setState({
                      highlightedPick: false
                    }, ()=>{
                        if(vm.state.gameType === "immediate"){
                            vm.startVideo();
                        }
                        vm.checkIfGetsPrize();
                    })
                  } else {
                    console.log(error);
                    confirmAlert({
                        title: 'There was an error',
                        variables: vm.props.variables,
                        message: error.message,
                        buttons: [
                          {
                            label: 'OK',
                          }
                        ]
                      })
                  }
                })
              }
            },
            {
              label: 'No',
              onClick: () => console.log("dismiss")
            }
          ],
      })
    }

    checkIfGetsPrize(alertAboutWinningImmediatelyAfterward=false) {
        if(this.state.gameType !== "immediate"){
            return;
        }
        const winners = [];
        const racers = this.state.racers || [];
        for(const i in racers){
            const racer = racers[i];
            if(racer.id === this.state.winner){
                winners.push(racer.id);
            }
        }
        const isMlbApp =  process.env.REACT_APP_IS_MLB_TEAM === "true";
        const userResponse = {};
        const userEmail = this.state.userEmail;
        let base64EncodedEmail = userEmail;
        if(validateEmail(userEmail)){
            base64EncodedEmail = btoa(userEmail)
        }
        userResponse['uid'] = base64EncodedEmail;
        userResponse['isMlbTeam'] = isMlbApp;
        userResponse['mlbTeamId'] = process.env.REACT_APP_MLB_TEAM_ID;
        userResponse['winner'] = this.state.winner;
        userResponse['useNewEmail'] = true;
        if(alertAboutWinningImmediatelyAfterward){
            this.setState({
                loading: true
            })
        }
        let url = `https://us-central1-${process.env.REACT_APP_FIREBASE_PROJECT_ID}.cloudfunctions.net/api/sendHiddenBallPrize`;
        if(process.env.NODE_ENV === "development"){
            url = `http://localhost:5001/${process.env.REACT_APP_FIREBASE_PROJECT_ID}/us-central1/api/sendHiddenBallPrize`;
        }
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(userResponse)
        }).then(async res => {
            let prizeResult = "noResult";
            if(alertAboutWinningImmediatelyAfterward){
                this.setState({
                    loading: false
                })
            }
            if(!res){
                this.setState({
                    prizeResult: prizeResult
                })
                return;
            }
            const value = await Promise.resolve(res.json());
            this.handlePrizeResult(value, alertAboutWinningImmediatelyAfterward, winners);
        }).catch((e) => {
            this.setState({
                loading: false
            })
            console.log(e)
        })
    }

    handlePrizeResult(value, alertAboutWinningImmediatelyAfterward, winners){
        let prizeResult = "noResult";
        if(value && (value.result === "main_reward_sent" || value.result === "prize_sent")){
            prizeResult = "winnerPrize"
        } else if(value.result === "losing_prize_sent") {
            prizeResult = "loserPrize"
        } else if(winners.indexOf(this.state.currentPick.pickid) !== -1) {
            prizeResult = "winnerNoPrize"
        } else {
            prizeResult = "loserNoPrize"
        }
        this.setState({
            prizeResult: prizeResult
        }, ()=>{
            if(alertAboutWinningImmediatelyAfterward){
                this.alertIfWonOrLost(prizeResult);
            }
        })
    }

    startVideo(typeOfVideo){
        if(this.state.modal || !this.state.winner) return;
        let videoUrl = "";
        const racers = this.state.racers;
        for(const racerIndex in racers){
            if(racers[racerIndex].id === this.state.winner){
                if(typeOfVideo === "shuffle"){
                    videoUrl = racers[racerIndex].shuffleLink;
                } else {
                    videoUrl = racers[racerIndex].revealLink;
                }
                this.setState({
                    videoURL: videoUrl,
                    modal: true
                })
            }
        }
    }

    renderHoldingScreen(){
        const tenantVariables = this.props.variables || {};
        const textAbovePlayingText = tenantVariables.textAbovePlayingText || "Next game starts during the";
        const textColor = tenantVariables.textColor || "#fff";
        return(
            <>
                <div className="hero-text-container">
                  <img onError={()=>this.setState({imageLoading: false})} onLoad={()=> this.setState({imageLoading: false})} src={tenantVariables.frontLogoImage} className="main-hero-image" alt=""/>
                </div>
                <p style={{display: tenantVariables.playingText ? '' : "none", color:textColor}}>{textAbovePlayingText} <br/><span className="emphasizedText" style={{backgroundColor:tenantVariables.primaryColor, color:tenantVariables.secondaryColor}}>{tenantVariables.playingText}</span></p>
            </>
        )
    }

    renderPlayingScreen(){
      const tenantVariables = this.props.variables || {};
      const racers = this.state.racers;
      let videoURL = this.state.videoURL || "";
      const gameType = this.state.gameType;
      const sentEmail = this.state.sentEmail || false;
      const whoWillWinText = tenantVariables.aboveSelectionsText || "Where is the object hidden?";
      const opacityTenantVariables = tenantVariables.opacityBackgroundVideo || "1";
      const isMlbApp =  process.env.REACT_APP_IS_MLB_TEAM === "true";
      const config = {
        file: {
            attributes: {
                autoPictureInPicture: false,
                disablePictureInPicture: true,
                controlsList: "nodownload"
            }
        }
      }

      return(
            <>
                <div className="user" style={{marginTop: 10}}>
                  <img onLoad={()=> this.setState({imageLoading: false})} onError={()=>this.setState({imageLoading: false})} src={tenantVariables.topLeftImage} alt="" style={{marginBottom:0, maxWidth:"100%", zoom: '45%'}}/>
                </div>
                <div className="top-text">
                  <p className="top-text-mobile" style={{wordWrap: "break-word", fontWeight:700, color: tenantVariables.textColor}}>{whoWillWinText}</p>
                </div>
                {this.state.currentPick.pickid &&
                    <div className="top-text">
                        <p className="top-text-mobile" style={{wordWrap: "break-word", fontWeight:700, color: tenantVariables.myPickTextColor, background: tenantVariables.highlightPickColor}}>{tenantVariables.myPickText || "My Pick"}</p>
                    </div>
                }
                <Carousel interval={90000000} autoPlay={false} showArrows={!this.state.currentPick.pickid && true} showStatus={false} showIndicators={false} showThumbs={false} selectedItem={this.state.selectedItem}
                    onChange={(e) => {
                                this.setState({
                                    selectedItem: e,
                                });
                            }}>
                      {
                        racers.filter(item =>
                            this.state.currentPick.pickid ? item.id === this.state.currentPick.pickid : true
                        ).map(function(item,i){
                          if(item.id === this.state.currentPick.pickid || item.id === this.state.highlightedPick){
                            return <div
                                      key={i}
                                      id={item.id}
                                      className="grid-item"
                                      style={{background: tenantVariables.highlightPickColor, boxShadow: "none", border: "none"}}>
                                      <img onLoad={()=> this.setState({imageLoading: false})} onError={()=> this.setState({imageLoading: false})} src={item.gifSelectionImage} style={{maxWidth: 250, maxHeight: 250}} alt=""/>
                                    </div>
                          } else {
                            return <div
                                      key={i}
                                      id={item.id}
                                      className="grid-item"
                                      onClick={()=>this.onClickPick(item, i)}
                                      style={{background: 'transparent', boxShadow: "none", border: "none"}}>
                                      <img onLoad={()=> this.setState({imageLoading: false})} onError={()=> this.setState({imageLoading: false})} src={item.gifSelectionImage} style={{maxWidth: 250, maxHeight: 250}} alt="" />
                                    </div>
                          }
                        }, this)
                      }
                </Carousel>
                <style dangerouslySetInnerHTML={{
                  __html: [
                     '.carousel .control-next.control-arrow:before {',
                     'border-left: 32px solid ' + tenantVariables.highlightPickColor,
                     ';border-top: 16px solid transparent',
                     ';border-bottom: 16px solid transparent',
                     '}',
                     '.carousel .control-prev.control-arrow:before {',
                     'border-right: 32px solid ' + tenantVariables.highlightPickColor,
                     ';border-top: 16px solid transparent',
                     ';border-bottom: 16px solid transparent',
                     '}',
                     '.carousel .thumb.selected, .carousel .thumb:hover {',
                     '  border: 3px solid ' + tenantVariables.highlightPickColor,
                     '}'
                    ].join('\n')
                  }}>
                </style>
                <button className="btn btn-normal" style={{ display: this.state.highlightedPick ? "": "none", color: tenantVariables.secondaryColor, backgroundColor: tenantVariables.primaryColor, marginTop:"25px", width:"300px", fontSize:"1.5rem"}} onClick={()=>this.onSubmitAnswer()}>SUBMIT PICK</button>
                <button className="btn btn-normal" style={{ display: this.state.currentPick.pickid ? "none": "", color: tenantVariables.secondaryColor, backgroundColor: tenantVariables.primaryColor, marginTop:"25px", width:"300px", fontSize:"1.5rem"}} onClick={()=>this.startVideo("shuffle")}>REPLAY VIDEO</button>
                <button className="btn btn-normal" style={{ display: ((this.state.currentPick.pickid && gameType === "immediate") || sentEmail) ? "": "none", color: tenantVariables.secondaryColor, backgroundColor: tenantVariables.primaryColor, marginTop:"25px", width:"300px", fontSize:"1.5rem"}} onClick={()=>this.startVideo()}>REPLAY REVEAL VIDEO</button>
                <style dangerouslySetInnerHTML={{
                    __html: [
                        '.solidBlackBackground {',
                        'opacity: '+ opacityTenantVariables +' !important'
                    ].join('\n')
                }}>
                </style>
                <Modal backdropClassName="solidBlackBackground" isOpen={(this.state.modal && videoURL)} style={{width: '90%'}} id="myModal" contentClassName="transparentBackground" toggle={() => this.setVideoOverOrToggled()}>
                {/*<Modal isOpen={(this.state.modal && videoURL)} style={{width: '90%'}} id="myModal" contentClassName="transparentBackground" toggle={this.toggle}>*/}
                    {isMlbApp ?
                        <ReactPlayer config={config} stopOnUnmount={true} pip={false} playsinline={false} volume={null} url={videoURL} muted={false} playing={false} onEnded={() => this.setVideoOverOrToggled()} controls={true} width="100%" height="auto"/>
                    :
                        <ReactPlayer playsinline={true} volume={0} url={videoURL} muted={true} playing={true} onEnded={() => this.setVideoOverOrToggled()} controls={false} width="100%" height="auto"/>
                    }
                </Modal>
            </>
        )
    }

    throwAlert(typeOfAlert){
        const tenantVariables = this.props.variables || {};
        let title, message, confirmationMessageImage;
        if(typeOfAlert === "winning"){
            title = tenantVariables.winningHeader || "You Win";
            message = tenantVariables.winningMessage || "Great pick";
            confirmationMessageImage = tenantVariables.confirmationMessageImage || "";
        } else if(typeOfAlert === "winning_no_prize"){
            title = tenantVariables.winningNoPrizeHeader || "You Win!";
            message = tenantVariables.winningNoPrizeMessage || "Unfortunately all prizes have been given out";
        } else if(typeOfAlert === "losing"){
            title = tenantVariables.losingHeaderPrize || "You Lose!";
            message = tenantVariables.losingMessagePrize || "But you still get a prize!";
        } else if(typeOfAlert === "losing_no_prize"){
            title = tenantVariables.losingHeader || "Oh No!";
            message = tenantVariables.losingMessage || "Looks like you got the answer wrong. Come back next time to try again!";
        } else if(typeOfAlert === "already_happened"){
            title = tenantVariables.alreadyHappenedHeader || "Oh no!"
            message = tenantVariables.alreadyHappenedMessage || "The contest already happened but come back next time to try again!"
        }
        if(title){
            confirmAlert({
                messageImage: confirmationMessageImage,
                title: title,
                variables: tenantVariables,
                message: message,
                buttons: [],
            })
        } else {
            console.log("NO TITLE")
        }
    }

    alertIfWonOrLost(prizeResultPassed){
        const prizeResult = this.state.prizeResult || prizeResultPassed;
        const winners = [];
        const racers = this.state.racers || [];
        for(const i in racers){
            const racer = racers[i];
            if(racer.id === this.state.winner){
                winners.push(racer.id);
            }
        }
        if(typeof this.state.currentPick.pickid === "undefined"){
            this.throwAlert("already_happened")
        } else {
            const hasRightAnswer = winners.indexOf(this.state.currentPick.pickid) !== -1;
            if(prizeResult === "noResult" && hasRightAnswer){
                this.throwAlert("winning_no_prize")
            } else if(prizeResult === "winnerPrize" && hasRightAnswer) {
                this.throwAlert("winning")
                this.setState({
                    startConfetti: true
                })
            } else if(prizeResult === "loserPrize" && !hasRightAnswer) {
                this.throwAlert("losing")
            } else if(prizeResult === "winnerNoPrize" && hasRightAnswer) {
                this.throwAlert("winning_no_prize")
            } else if(prizeResult === "loserNoPrize" && !hasRightAnswer) {
                this.throwAlert("losing_no_prize")
            } else if(!prizeResult && hasRightAnswer){
                this.throwAlert("winning_no_prize")
            } else {
                this.throwAlert("losing_no_prize")
            }
        }
    }

    render() {
        const tenantVariables = this.props.variables || {};
        const tenantRules = this.state.tenantRules || {};
        const logOutButtonColor = tenantVariables.logOutButtonColor || "white";
        const primaryColor = tenantVariables.primaryColor || "black";
        const secondaryColor = tenantVariables.secondaryColor || "white";
        const rulesInAppButtonText = tenantRules.rulesInAppButtonText || "Rules & Regs";
        const mlbPrivacyPolicyLink = tenantRules.mlbPrivacyPolicyLink || "https://www.mlb.com/app/ballpark/official-information/privacy-policy";
        let rulesShowInAppPopUpHeader = tenantRules.rulesShowInAppPopUpHeader;
        let rulesShowInAppPopUpText = tenantRules.rulesShowInAppPopUpText;
        let rulesPopUpText = tenantRules.rulesPopUpText;
        let rulesPopUpHeader = tenantRules.rulesPopUpHeader;
        const supportHeader = tenantRules.supportHeader || "NEED SUPPORT?";
        const supportText = tenantRules.supportText || "Email customerservice@website.mlb.com with your issue and we'll be in touch!";
        const link = tenantRules.rulesAndRegsLink;
        const isMlbApp = process.env.REACT_APP_IS_MLB_TEAM === "true";
        const isBearsApp = process.env.REACT_APP_FIREBASE_PROJECT_ID === "chicagobearsshuffle";
        const rulesShowInApp = tenantRules.rulesShowInApp || (isMlbApp && !tenantVariables.noMandatoryTermsAndConditions) || false;
        if(rulesShowInApp) {
            if(!rulesShowInAppPopUpText && rulesPopUpText){
                rulesShowInAppPopUpText = rulesPopUpText;
            }
            if(!rulesShowInAppPopUpHeader && rulesPopUpHeader){
                rulesShowInAppPopUpHeader = rulesPopUpHeader;
            }
        }
        if(!this.state.userEmail){
          let nextUrl = 'login'
            return <Redirect to={nextUrl} />
        } else if(!tenantVariables.doNotCollectEmail && !isMlbApp && !validateEmail(this.state.userEmail)){
            this.logout();
        } else if((tenantVariables.doNotCollectEmail || isMlbApp) && validateEmail(this.state.userEmail)){
            this.logout();
        }
        if (this.state.loading === true) {
            return (
                <Loading primaryColor={tenantVariables.primaryColor} loading={this.state.loading} backgroundImage={tenantVariables.backgroundImage}/>
            )
        }
        let renderHoldingScreen = false;
        let renderMainScreen = "";
        if(this.state.gameActive && !this.state.locationChecking){
            renderMainScreen = this.renderPlayingScreen()
        } else {
            renderHoldingScreen = true;
            renderMainScreen = this.renderHoldingScreen()
        }
        return(
          <div className="flex-container-home" style={{backgroundImage: "url(" + tenantVariables.backgroundImage + ")"}}>
              {this.state.startConfetti &&
                <Confetti width={this.state.width} height={this.state.height} style={{zIndex: 1 }}/>
              }
              <div className="flex-content-container-home">
                  <div className="intro-container-home" style={{margin: "auto"}}>
                      <div className="grid-wrapper">
                          {(!this.state.imageLoading || (!tenantVariables.frontLogoImage && renderHoldingScreen)) &&
                              <div className="flex-header-home" style={{marginTop:10, justifyContent: "space-between", maxWidth: "100%", minWidth: 280, width:"100vw"}}>
                                  {isMlbApp &&
                                      <div style={{width: "25%", height: 38, color: logOutButtonColor, borderColor: logOutButtonColor, border: "solid 1px", borderRadius: 5, flexGrow:1, maxWidth: 100}}>
                                          <button className="btn" style={{color: logOutButtonColor, width: "100%", height: "100%", backgroundColor: "transparent", padding: "6px 12px"}} onClick={() => { this.toggleSupport() }}>
                                              <Textfit mode="single" max={16}>
                                                  Support
                                              </Textfit>
                                          </button>
                                      </div>
                                  }
                                  {isMlbApp &&
                                      <div style={{ width: "25%", color: logOutButtonColor, borderColor: logOutButtonColor, height: 38, border: "solid 1px", borderRadius: 5, flexGrow:1, maxWidth: 100}}>
                                          <button className="btn" style={{color: logOutButtonColor, width: "100%", height: "100%", backgroundColor: "transparent", padding: "6px 12px"}} onClick={() => {window.open("https://www.mlb.com/app/ballpark/official-information/terms-of-use", "_blank")}}>
                                              <Textfit mode="single" max={16}>
                                                  MLB TOU
                                              </Textfit>
                                          </button>
                                      </div>
                                  }
                                  {isMlbApp &&
                                      <div style={{ width: "25%", height: 38, color: logOutButtonColor, borderColor: logOutButtonColor, border: "solid 1px", borderRadius: 5, flexGrow:1, maxWidth: 100}}>
                                          <button className="btn" style={{color: logOutButtonColor, width: "100%", height: "100%", backgroundColor: "transparent", padding: "6px 12px"}} onClick={() => { window.open(mlbPrivacyPolicyLink, "_blank") }}>
                                              <Textfit mode="single" max={16}>
                                                  MLB Privacy Policy
                                              </Textfit>
                                          </button>
                                      </div>
                                  }
                                  {rulesShowInApp &&
                                      <div style={{width: "25%", height: 38, color: logOutButtonColor, borderColor: logOutButtonColor, border: "solid 1px", borderRadius: 5, maxWidth: 100}}>
                                          <button className="btn" style={{color: logOutButtonColor, width: "100%", height: "100%", backgroundColor: "transparent", padding: "6px 12px"}} onClick={() => { rulesShowInAppPopUpText?this.toggleRules():window.open(link, '_blank') }}>
                                              <Textfit mode="single" max={16}>
                                                  {rulesInAppButtonText}
                                              </Textfit>
                                          </button>
                                      </div>
                                  }
                                  <div style={{display: (isMlbApp || this.props.passedEmail || isBearsApp) && "none", width: "25%", height: 38, color: logOutButtonColor, borderColor: logOutButtonColor, border: "solid 1px", borderRadius: 5, marginLeft:"auto", maxWidth: 80}}>
                                      <button className="btn" onClick={() => { this.logout() }} style={{color: logOutButtonColor, width: "100%", height: "100%", backgroundColor: "transparent", padding: "6px 12px"}}>
                                          <Textfit mode="single" max={16}>
                                              LOG OUT
                                          </Textfit>
                                      </button>
                                  </div>
                              </div>
                          }
                          {renderMainScreen}
                      </div>
                  </div>
              </div>
              <Modal isOpen={this.state.modalRules} style={{width: '90%'}} id="rulesModal">
                  <ModalHeader style={{color: secondaryColor, padding:10}}>
                    <span style={{fontSize:25}}>
                        {rulesShowInAppPopUpHeader}
                    </span>
                  </ModalHeader>
                  <ModalBody>
                      <center className="container-out">
                          <div className="question-box question-form">
                              <p style={{width:"100%",height:200, overflow:"scroll",padding:10, borderBottom:"1px solid black", borderTop: "1px solid black", fontWeight:100, fontSize:14}} dangerouslySetInnerHTML={{ __html:rulesShowInAppPopUpText}}/>
                              <button className="btn btn-default btn-admin btn-cancel" onClick={() => { this.toggleRules(); }} style={{color: secondaryColor, backgroundColor: primaryColor, fontWeight: '700', marginTop:'20px', fontSize:'1.3 rem'}}>Dismiss</button>
                          </div>
                      </center>
                  </ModalBody>
              </Modal>
              <Modal isOpen={this.state.modalSupport} style={{width: '90%'}} id="rulesModal">
                  <ModalHeader style={{color: secondaryColor, padding:10}}>
                    <span style={{fontSize:25}}>
                        {supportHeader}
                    </span>
                  </ModalHeader>
                  <ModalBody>
                      <center className="container-out">
                          <div className="question-box question-form">
                              <p style={{width:"100%",overflow:"scroll",fontWeight:100, fontSize:14, marginBottom: 0}} dangerouslySetInnerHTML={{ __html:supportText}}/>
                              <button className="btn btn-default btn-admin btn-cancel" onClick={() => { this.toggleSupport(); }} style={{color: secondaryColor, backgroundColor: primaryColor, fontWeight: '700', fontSize:'1.3 rem'}}>Dismiss</button>
                          </div>
                      </center>
                  </ModalBody>
              </Modal>
          </div>
        )
    }
}

export default Main_App;
